// noinspection JSUnusedGlobalSymbols

export const FIND_PRIVATE_REGISTRATION_ORGANIZATION_TYPES = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationOrganizationTypes(registration: $registration) {
            code
            name
            icon
            group
            gameRank
            sponsorRank
            doneeRank
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_GAME_TYPES = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationGameTypes(registration: $registration) {
            code
            name
            icon
            gameRank
            hidden
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_OPTINS = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationOptins(registration: $registration) {
            code
            name
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_COUNTRIES = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationCountries(registration: $registration) {
            code
            name
            mainImage {
                url
            }
        }
    }
`;export const FIND_PRIVATE_REGISTRATION_LOCALES = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationLocales(registration: $registration) {
            code
            name
            mainImage {
                url
            }
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_GAME_SUBJECTS = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationGameSubjects(registration: $registration) {
            code
            name
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_GAME_OPTIONS = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationGameOptions(registration: $registration) {
            code
            name
            allowedForGameTypes
            forcedForGameTypes
            forcedForRules

        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_GAME_THEMES = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationGameThemes(registration: $registration) {
            code
            name
            colors
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_GAME_ASSETS_BY_TYPE = (gql: any) => gql`
    query($registration: RegistrationAuthInput, $type: String!) {
        findPrivateRegistrationGameAssetsByType(registration: $registration, type: $type) {
            name
            mainImage {
                url
            }
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_SALE_DATAS = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationSaleDatas(registration: $registration) {
            code
            name
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_TICKET_PRICE_AMOUNTS = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationTicketPriceAmounts(registration: $registration) {
            value
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_PLANNED_TICKETS = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationPlannedTickets(registration: $registration) {
            value
        }
    }
`;
export const FIND_PRIVATE_REGISTRATION_START_DELAYS = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        findPrivateRegistrationStartDelays(registration: $registration) {
            code
            name
        }
    }
`;
export const COMPLETE_PRIVATE_REGISTRATION = (gql: any) => gql`
    mutation($registration: RegistrationAuthInput!) {
        completePrivateRegistration(registration: $registration) {
            id
        }
    }
`;
export const GET_PRIVATE_REGISTRATION = (gql: any) => gql`
    query($registration: RegistrationAuthInput) {
        getPrivateRegistration(registration: $registration) {
            id
            type
            lastStep
            email
            phone
            lastName
            firstName
            status
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            requestedGameName
            requestedGameType
            requestedGameDescription
            requestedGamePresentation
            requestedGameOverline
            requestedOrganizationName
            requestedOrganizationDescription
            requestedOrganizationDomain
            requestedOrganizationPresentation
            requestedOrganizationOverline
            requestedThemeCode
            requestedCountry
            requestedLocale
            requestedZipCode
            requestedOrganizationType
            requestedCity
            requestedGameSubject
            requestedOrganizationWebsiteUrl
            requestedOrganizationFacebookUrl
            requestedTicketPriceCurrency
            requestedTicketPriceAmount
            requestedGameTheme
            requestedBunchList
            requestedPlannedStartedAt
            requestedPlannedDrawnAt
            requestedGameOptions
            optins
            requestedSaleData
            progressRatio
            progressStepName
            status
            gamePublicPageShortUrl
            gamePublicManagementUrl
            sponsorPublicPageShortUrl
            sponsorPublicManagementUrl
            doneePublicPageShortUrl
            doneePublicManagementUrl
            requestedSupportedLocales
            requestedPlannedTickets
            requestedPlannedCustomTickets
            requestedLogoImage { url }
            requestedBannerImage { url }
            requestedGimage1Image { url }
            requestedGimage2Image { url }
            requestedGimage3Image { url }
            requestedGimage4Image { url }
            requestedGimage5Image { url }
            requestedGimage6Image { url }
            requestedGimage7Image { url }
            requestedGimage8Image { url }
            plannedStartDelay
            userAuth
            organizationAuth
            ignoreOrganizationAuth
        }
    }
`;
export const GET_PRIVATE_REGISTRATION_PLAN_BY_CODE = (gql: any) => gql`
    query getPrivateRegistrationPlanByCode($registration: RegistrationAuthInput, $code: String!) {
        getPrivateRegistrationPlanByCode(registration: $registration, code: $code) {
            maxSales
            maxTickets
            maxPaymentAmount
            priceCurrency
            name
        }
    }
`;
export const CREATE_PUBLIC_RIDP_GAME_REGISTRATION = (gql: any) => gql`
    mutation($ridp: RidpAuthInput!, $data: CreatePublicRidpGameRegistrationInput) {
        createPublicRidpGameRegistration(ridp: $ridp, data: $data) {
            id
            privateToken
        }
    }
`;
export const DESCRIBE_PUBLIC_RIDP_FOR_GAME_REGISTRATION = (gql: any) => gql`
    query($ridp: RidpAuthInput!) {
        describePublicRidpForGameRegistration(ridp: $ridp) {
            email
        }
    }
`;
export const CREATE_PUBLIC_PARTNER_GAME_REGISTRATION = (gql: any) => gql`
    mutation($partner: String!, $data: CreatePublicPartnerGameRegistrationInput) {
        createPublicPartnerGameRegistration(partner: $partner, data: $data) {
            id
            privateToken
        }
    }
`;
export const CREATE_PUBLIC_PARTNER_SPONSOR_REGISTRATION = (gql: any) => gql`
    mutation($partner: String!, $data: CreatePublicPartnerSponsorRegistrationInput) {
        createPublicPartnerSponsorRegistration(partner: $partner, data: $data) {
            id
            privateToken
        }
    }
`;
export const CREATE_PUBLIC_PARTNER_DONEE_REGISTRATION = (gql: any) => gql`
    mutation($partner: String!, $data: CreatePublicPartnerDoneeRegistrationInput) {
        createPublicPartnerDoneeRegistration(partner: $partner, data: $data) {
            id
            privateToken
        }
    }
`;
