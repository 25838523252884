export function mount(mod: any, privatePrefix?: string, publatePrefix?: string, publicPrefix?: string) {
    const a = (privatePrefix || '').replace(/\/$/, '');
    const b = (publatePrefix || '').replace(/\/$/, '');
    const c = (publicPrefix || '').replace(/\/$/, '');

    return [
        ...(mod.private?.map((x: any) => ({...x, path: `${a}${x.path || ''}`})) || []),
        ...(mod.publate?.map((x: any) => ({...x, path: `${b}${x.path || ''}`})) || []),
        ...(mod.public?.map((x: any) => ({...x, path: `${c}${x.path || ''}`})) || []),
    ];
}

export default mount;