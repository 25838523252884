// noinspection JSUnusedGlobalSymbols

export * from './game';
export * from './misc';
export * from './organization';
export * from './user';
export * from './registration';
export * from './partner';

export const FIND_PRIVATE_ORGANIZATION_GAME_GIMPORTS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateOrganizationGameGimports(game: $game) {
            id
            privateToken
            status
            createdAt
        }
    }
`;
export const FIND_PRIVATE_ORGANIZATION_GAME_CHANGEREQUESTS = (gql: any) => gql`
    query($game: GameAuthInput) {
        findPrivateOrganizationGameChangerequests(game: $game) {
            id
            privateToken
            status
            createdAt
        }
    }
`;
export const GET_PRIVATE_GIMPORT = (gql: any) => gql`
    query($gimport: GimportAuthInput) {
        getPrivateGimport(gimport: $gimport) {
            id
            email
            status
            type
            game
            gameCode
            gameName
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            dataFile { url, name }
            data
            progressRatio
            progressStepName
            processedToIgnoreItemsCount
            processedToNochangeItemsCount
            processedToCreateItemsCount
            processedToDeleteItemsCount
            processedToUpdateItemsCount
            processedItemsCount
            toCreateItemsRatio
            toDeleteItemsRatio
            toUpdateItemsRatio
            toIgnoreItemsRatio
            toNochangeItemsRatio
        }
    }
`;
export const GET_PRIVATE_CHANGEREQUEST = (gql: any) => gql`
    query($changerequest: ChangerequestAuthInput) {
        getPrivateChangerequest(changerequest: $changerequest) {
            id
            status
        }
    }
`;
export const GET_PRIVATE_GIMPORT_GAME = (gql: any) => gql`
    query($gimport: GimportAuthInput) {
        getPrivateGimportGame(gimport: $gimport) {
            publicPageShortUrl
            ticketsImportPrivateUrl
        }
    }
`;
export const GET_PRIVATE_CHANGEREQUEST_GAME = (gql: any) => gql`
    query($changerequest: ChangerequestAuthInput) {
        getPrivateChangerequestGame(changerequest: $changerequest) {
            publicPageShortUrl
            changerequestPrivateUrl
        }
    }
`;
export const GET_PRIVATE_SALE = (gql: any) => gql`
    query getPrivateSale($sale: SaleAuthInput) {
        getPrivateSale(sale: $sale) {
            id
            token
            status
            lastName
            firstName
            email
            phone
            gameName
            organizationName
            tickets
            publicPageShortUrl
            paymentAmount
            paymentCurrency
            createdAt
            personalDataStatus
            ownerCity
            ownerCountry
            ownerZipCode
            ownerAddress
            ownerComplement
        }
    }
`;
export const GET_PRIVATE_SPONSORING = (gql: any) => gql`
    query getPrivateSponsoring($sponsoring: SponsoringAuthInput) {
        getPrivateSponsoring(sponsoring: $sponsoring) {
            id
            privateToken
            status
            gameName
            organizationName
            createdAt
            name
            lastName
            firstName
            email
            phone
            personalDataStatus
            websiteUrl
        }
    }
`;
export const GET_PRIVATE_BOOK = (gql: any) => gql`
    query getPrivateBook($book: BookAuthInput) {
        getPrivateBook(book: $book) {
            attributionStatus
            sellergroup
            sellergroupName
            seller
            customCode
		    sellerFirstName
            sellerLastName
            game
            id
            code
            paymentStatus
            onboardingStatus
            ticketPriceAmount
            ticketPriceCurrency
            gameBooksPaymentStatus
            payPublicPageUrl
            publicToken
            salesInfo
            gameSellersVisibilityMode
            gameSellersSortMode
            ticketsSalesInfo {
                tickets {
                    customCode
                    firstName
                    lastName
                    email
                    phone
                    status
                    paymentStatus
                }
  	            markedAsSold
            }
            bookpayments {
                count
      	        items {
                    createdAt
                    firstName
                    lastName
                    email
                    paymentProvider
                    paymentAmount
		    status
                }
            }
        }
    }
`;
export const GET_INVITATION_BY_JWT_TOKEN = (gql: any) => gql`
    query getInvitationByJwtToken($token: String!) {
        getInvitationByJwtToken(token: $token) {
            type
            data {
                ...on Account {
                    links {
                        name
                        type
                        url
                    }
                }
            }
        }
    }
`;
export const GET_PRIVATE_GROUPING = (gql: any) => gql`
    query getPrivateGrouping($grouping: GroupingAuthInput) {
        getPrivateGrouping(grouping: $grouping) {
            game
            name
            code
            customCode
            email
            publicEmail
            id
            publicToken
        }
    }
`;
export const GET_PUBLIC_ORGANIZATION_ACCOUNT_CONNECT_URL_BY_ID_AND_MODULE_CODE = (gql: any) => gql`
    query getPublicOrganizationAccountConnectUrlByIdAndModuleCode($id: String!, $moduleCode: String!) {
        getPublicOrganizationAccountConnectUrlByIdAndModuleCode(id: $id, moduleCode: $moduleCode) {
            module
            moduleCode
            moduleName
            organization
            organizationCode
            organizationName
            accountConnectUrl
            status
            tenantCode
        }
    }
`;
export const FIND_PRIVATE_GROUPING_SELLERGROUPS = (gql: any) => gql`
    query findPrivateGroupingSellergroups($grouping: GroupingAuthInput) {
        findPrivateGroupingSellergroups(grouping: $grouping) {
            id
            name
        }
    }
`;
export const FIND_PRIVATE_GROUPING_GROUPINGS = (gql: any) => gql`
    query findPrivateGroupingGroupings($grouping: GroupingAuthInput) {
        findPrivateGroupingGroupings(grouping: $grouping) {
                name
                id
                publicToken
        }
    }
`;
export const FIND_GAME_SELLERGROUPS = (gql: any) => gql`
    query findGameSellergroups($game: ID) {
        findGameSellergroups(game: $game) {
            id
            name
        }
    }
`;
export const FIND_SELLERGROUP_SELLERS = (gql: any) => gql`
    query findSellergroupSellers($sellergroup: ID) {
        findSellergroupSellers(sellergroup: $sellergroup) {
            id
            firstName
            lastName
        }
    }
`;
export const FIND_SELLER_BOOKS = (gql: any) => gql`
    query findSellerBooks($seller: ID) {
        findSellerBooks(seller: $seller) {
            id
            publicToken
            salesInfo
            paymentStatus
            customCode
            code
            verificationStatus
            statPaidAmount
            ticketPriceCurrency
            tickets
            ticketsSalesInfo {
                tickets {
                    customCode
                    status
                    lastName
                    firstName
                    phone
                    email
                }
                sales {
                    lastName
                    firstName
                    phone
                    email
                    tickets
                }
            }
        }
    }
`;
export const ATTRIBUTE_PRIVATE_BOOK = (gql: any) => gql`
    mutation attributePrivateBook($book: BookAuthInput!, $data: AttributePrivateBookInput!) {
        attributePrivateBook(book: $book, data: $data) {
            id
        }
    }
`;
export const RESEND_INVITATION_BY_JWT_TOKEN = (gql: any) => gql`
    mutation resendInvitationByJwtToken($token: String!) {
        resendInvitationByJwtToken(token: $token) {
            id
        }
    }
`;
export const SAVE_BOOK_SALES = (gql: any) => gql`
    mutation saveBookSales($book: BookAuthInput!, $data: [SaleInfoInput]!) {
        saveBookSales(book: $book, data: $data) {
            id
        }
    }
`;
export const ADD_BOOK_SALES = (gql: any) => gql`
    mutation addBookSales($book: BookAuthInput!, $data: [SaleInfoInput]!) {
        addBookSales(book: $book, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_REGISTRATION = (gql: any) => gql`
    mutation updatePrivateRegistration($registration: RegistrationAuthInput!, $data: UpdatePrivateRegistrationInput) {
        updatePrivateRegistration(registration: $registration, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_CHANGEREQUEST = (gql: any) => gql`
    mutation updatePrivateChangerequest($changerequest: ChangerequestAuthInput!, $data: UpdatePrivateChangerequestInput) {
        updatePrivateChangerequest(changerequest: $changerequest, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_GIMPORT = (gql: any) => gql`
    mutation updatePrivateGimport($gimport: GimportAuthInput!, $data: UpdatePrivateGimportInput) {
        updatePrivateGimport(gimport: $gimport, data: $data) {
            id
        }
    }
`;
export const VERIFY_PRIVATE_BOOK = (gql: any) => gql`
    mutation verifyPrivateBook($book: BookAuthInput!) {
        verifyPrivateBook(book: $book) {
            id
        }
    }
`;
export const INVALID_PRIVATE_BOOK = (gql: any) => gql`
    mutation invalidPrivateBook($book: BookAuthInput!) {
        invalidPrivateBook(book: $book) {
            id
        }
    }
`;
export const UPDATE_SALE_PERSONAL_DATA = (gql: any) => gql`
    mutation updateSalePersonalData($sale: SaleAuthInput!, $data: UpdateSalePersonalDataInput!) {
        updateSalePersonalData(sale: $sale, data: $data) {
            id
        }
    }
`;
export const UPDATE_SPONSORING_PERSONAL_DATA = (gql: any) => gql`
    mutation updateSponsoringPersonalData($sponsoring: SponsoringAuthInput!, $data: UpdateSponsoringPersonalDataInput!) {
        updateSponsoringPersonalData(sponsoring: $sponsoring, data: $data) {
            id
        }
    }
`;
export const PAUSE_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation pausePrivateOrganizationGame($game: GameAuthInput) {
        pausePrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const CLOSE_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation closePrivateOrganizationGame($game: GameAuthInput) {
        closePrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const START_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation startPrivateOrganizationGame($game: GameAuthInput) {
        startPrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const START_PRIVATE_GIMPORT = (gql: any) => gql`
    mutation startPrivateGimport($gimport: GimportAuthInput) {
        startPrivateGimport(gimport: $gimport) {
            id
            status
        }
    }
`;
export const PUBLISH_PRIVATE_CHANGEREQUEST = (gql: any) => gql`
    mutation publishPrivateChangerequest($changerequest: ChangerequestAuthInput) {
        publishPrivateChangerequest(changerequest: $changerequest) {
            id
            status
        }
    }
`;
export const DRAW_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation drawPrivateOrganizationGame($game: GameAuthInput) {
        drawPrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const PUBLISH_RESULTS_OF_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation publishResultsOfPrivateOrganizationGame($game: GameAuthInput) {
        publishResultsOfPrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const UNPUBLISH_RESULTS_OF_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation unpublishResultsOfPrivateOrganizationGame($game: GameAuthInput) {
        unpublishResultsOfPrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const NOTIFY_PARTICIPANTS_OF_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation notifyParticipantsOfPrivateOrganizationGame($game: GameAuthInput) {
        notifyParticipantsOfPrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const NOTIFY_WINNING_PARTICIPANTS_OF_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation notifyWinningParticipantsOfPrivateOrganizationGame($game: GameAuthInput) {
        notifyWinningParticipantsOfPrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const NOTIFY_LOOSING_PARTICIPANTS_OF_PRIVATE_ORGANIZATION_GAME = (gql: any) => gql`
    mutation notifyLoosingParticipantsOfPrivateOrganizationGame($game: GameAuthInput) {
        notifyLoosingParticipantsOfPrivateOrganizationGame(game: $game) {
            id
            status
        }
    }
`;
export const SET_PRIVATE_BOOK_ONBOARDING_COMPLETE = (gql: any) => gql`
    mutation setPrivateBookOnboardingComplete($book: BookAuthInput) {
        setPrivateBookOnboardingComplete(book: $book) {
            id
        }
    }
`;
export const SET_MODULE_ORGANIZATION_ACCOUNT_PARAMS = (gql: any) => gql`
    mutation setModuleOrganizationAccountParams($id: String!, $moduleCode: String!, $params: ParamsInput!) {
        setModuleOrganizationAccountParams(id: $id, moduleCode: $moduleCode, params: $params) {
            id
        }
    }
`;

export const CREATE_PRIVATE_ORGANIZATION_GAME_CHANGEREQUEST = (gql: any) => gql`
    mutation($game: GameAuthInput) {
        createPrivateOrganizationGameChangerequest(game: $game) {
            id
            privateToken
        }
    }
`;
export const CREATE_PRIVATE_ORGANIZATION_GAME_TICKETS_GIMPORT = (gql: any) => gql`
    mutation($game: GameAuthInput, $data: CreatePrivateOrganizationGameTicketsGimportInput) {
        createPrivateOrganizationGameTicketsGimport(game: $game, data: $data) {
            id
            privateToken
        }
    }
`;
export const CREATE_PRIVATE_ORGANIZATION_GAME_SALES_GIMPORT = (gql: any) => gql`
    mutation($game: GameAuthInput, $data: CreatePrivateOrganizationGameSalesGimportInput) {
        createPrivateOrganizationGameSalesGimport(game: $game, data: $data) {
            id
            privateToken
        }
    }
`;
export const CREATE_PRIVATE_ORGANIZATION_GAME_SELLER = (gql: any) => gql`
    mutation($game: GameAuthInput, $data: CreatePrivateOrganizationGameSellerInput) {
        createPrivateOrganizationGameSeller(game: $game, data: $data) {
            id
        }
    }
`;
export const CREATE_PRIVATE_ORGANIZATION_GAME_SELLERGROUP = (gql: any) => gql`
    mutation($game: GameAuthInput, $data: CreatePrivateOrganizationGameSellergroupInput) {
        createPrivateOrganizationGameSellergroup(game: $game, data: $data) {
            id
        }
    }
`;
export const MARK_PRIVATE_ORGANIZATION_GAME_SALE_PAID = (gql: any) => gql`
    mutation markPrivateOrganizationGameSalePaid($game: GameAuthInput, $id: ID) {
        markPrivateOrganizationGameSalePaid(game: $game, id: $id) {
            id
        }
    }
`;
export const CANCEL_PRIVATE_ORGANIZATION_GAME_SALE = (gql: any) => gql`
    mutation cancelPrivateOrganizationGameSale($game: GameAuthInput, $id: ID) {
        cancelPrivateOrganizationGameSale(game: $game, id: $id) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_ORGANIZATION_GAME_TICKET = (gql: any) => gql`
    mutation updatePrivateOrganizationGameTicket($game: GameAuthInput, $id: ID, $data: UpdatePrivateOrganizationGameTicketInput!) {
        updatePrivateOrganizationGameTicket(game: $game, id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_ORGANIZATION_GAME_GODFATHER = (gql: any) => gql`
    mutation updatePrivateOrganizationGameGodfather($game: GameAuthInput, $id: ID, $data: UpdatePrivateOrganizationGameGodfatherInput!) {
        updatePrivateOrganizationGameGodfather(game: $game, id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_ORGANIZATION_GAME_SELLER = (gql: any) => gql`
    mutation updatePrivateOrganizationGameSeller($game: GameAuthInput, $id: ID, $data: UpdatePrivateOrganizationGameSellerInput!) {
        updatePrivateOrganizationGameSeller(game: $game, id: $id, data: $data) {
            id
        }
    }
`;
export const UPDATE_PRIVATE_ORGANIZATION_GAME_SELLERGROUP = (gql: any) => gql`
    mutation updatePrivateOrganizationGameSellergroup($game: GameAuthInput, $id: ID, $data: UpdatePrivateOrganizationGameSellergroupInput!) {
        updatePrivateOrganizationGameSellergroup(game: $game, id: $id, data: $data) {
            id
        }
    }
`;
export const ACTIVATE_PRIVATE_ORGANIZATION_GAME_TICKET_DRAW_AVAILABILITY_STATUS = (gql: any) => gql`
    mutation activatePrivateOrganizationGameTicketDrawAvailabilityStatus($game: GameAuthInput, $id: ID) {
        activatePrivateOrganizationGameTicketDrawAvailabilityStatus(game: $game, id: $id) {
            id
        }
    }
`;
export const DEACTIVATE_PRIVATE_ORGANIZATION_GAME_TICKET_DRAW_AVAILABILITY_STATUS = (gql: any) => gql`
    mutation deactivatePrivateOrganizationGameTicketDrawAvailabilityStatus($game: GameAuthInput, $id: ID) {
        deactivatePrivateOrganizationGameTicketDrawAvailabilityStatus(game: $game, id: $id) {
            id
        }
    }
`;
export const ACTIVATE_PRIVATE_ORGANIZATION_GAME_SALE_DRAW_AVAILABILITY_STATUS = (gql: any) => gql`
    mutation activatePrivateOrganizationGameSaleDrawAvailabilityStatus($game: GameAuthInput, $id: ID) {
        activatePrivateOrganizationGameSaleDrawAvailabilityStatus(game: $game, id: $id) {
            id
        }
    }
`;
export const DEACTIVATE_PRIVATE_ORGANIZATION_GAME_SALE_DRAW_AVAILABILITY_STATUS = (gql: any) => gql`
    mutation deactivatePrivateOrganizationGameSaleDrawAvailabilityStatus($game: GameAuthInput, $id: ID) {
        deactivatePrivateOrganizationGameSaleDrawAvailabilityStatus(game: $game, id: $id) {
            id
        }
    }
`;
export const DELETE_PRIVATE_ORGANIZATION_GAME_PROMISE = (gql: any) => gql`
    mutation deletePrivateOrganizationGamePromise($game: GameAuthInput, $id: ID) {
        deletePrivateOrganizationGamePromise(game: $game, id: $id) {
            id
        }
    }
`;
export const DELETE_PRIVATE_ORGANIZATION_GAME_PAYMENTMETHOD = (gql: any) => gql`
    mutation deletePrivateOrganizationGamePaymentmethod($game: GameAuthInput, $id: ID) {
        deletePrivateOrganizationGamePaymentmethod(game: $game, id: $id) {
            id
        }
    }
`;

export const SUBSCRIBE_PRIVATE_ORGANIZATION_GAME_PLAN = (gql: any) => gql`
    mutation subscribePrivateOrganizationGamePlan($game: GameAuthInput, $id: ID) {
        subscribePrivateOrganizationGamePlan(game: $game, id: $id) {
            id
        }
    }
`;
export const BUY_PRIVATE_ORGANIZATION_GAME_BOOKSETS = (gql: any) => gql`
    mutation buyPrivateOrganizationGameBooksets($game: GameAuthInput, $data: BuyPrivateOrganizationGameBooksetsInput) {
        buyPrivateOrganizationGameBooksets(game: $game, data: $data) {
            id
        }
    }
`;
export const SUBSCRIBE_PRIVATE_ORGANIZATION_GAME_BOOKS = (gql: any) => gql`
    mutation subscribePrivateOrganizationGameBooks($game: GameAuthInput) {
        subscribePrivateOrganizationGameBooks(game: $game) {
            id
        }
    }
`;
export const SUBSCRIBE_PRIVATE_ORGANIZATION_GAME_MULTI_DRAW = (gql: any) => gql`
    mutation subscribePrivateOrganizationGameMultiDraw($game: GameAuthInput) {
        subscribePrivateOrganizationGameMultiDraw(game: $game) {
            id
        }
    }
`;
export const SUBSCRIBE_PRIVATE_ORGANIZATION_GAME_DRAW_TICKETS = (gql: any) => gql`
    mutation subscribePrivateOrganizationGameDrawTickets($game: GameAuthInput) {
        subscribePrivateOrganizationGameDrawTickets(game: $game) {
            id
        }
    }
`;
export const SUBSCRIBE_PRIVATE_ORGANIZATION_GAME_SELLERS = (gql: any) => gql`
    mutation subscribePrivateOrganizationGameSellers($game: GameAuthInput) {
        subscribePrivateOrganizationGameSellers(game: $game) {
            id
        }
    }
`;
export const ACTIVATE_PRIVATE_ORGANIZATION_GAME_FLEX = (gql: any) => gql`
    mutation activatePrivateOrganizationGameFlex($game: GameAuthInput) {
        activatePrivateOrganizationGameFlex(game: $game) {
            id
        }
    }
`;
export const DEACTIVATE_PRIVATE_ORGANIZATION_GAME_FLEX = (gql: any) => gql`
    mutation deactivatePrivateOrganizationGameFlex($game: GameAuthInput) {
        deactivatePrivateOrganizationGameFlex(game: $game) {
            id
        }
    }
`;
