// noinspection JSUnusedGlobalSymbols

export const GET_PRIVATE_USER_PUBLIC_INFOS = (gql: any) => gql`
    query($user: UserAuthInput) {
        getPrivateUserPublicInfos(user: $user) {
            id
            firstName
            lastName
            status
            email
            phone
        }
    }
`;
export const GET_CURRENT_USER_PRIVATE_LOGIN_LINK = (gql: any) => gql`
    query($type: String!, $id: String!) {
        getCurrentUserPrivateLoginLink(type: $type, id: $id) {
            type
            link
        }
    }
`;
export const GET_CURRENT_USER = (gql: any) => gql`
    query {
        getCurrentUser {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
        }
    }
`;

export const GET_CURRENT_USER_WITH_SCOPES = (gql: any) => gql`
    query {
        getCurrentUserWithScopes: getCurrentUser {
            id
            email
            firstName
            lastName
            createdAt
            updatedAt
            status
            role
            scopes {
                items {
                    id
                    type
                    ...on GameUserScope {
                        game
                        gameCode
                        gameName
                        gameStatus
                        gameCreatedAt
                        gameStatPaidSales
                        gameStatPaidAmount
                        gameStatTickets
                        gameTicketPriceAmount
                        gameTicketPriceCurrency
                        gameType
                    }
                    ...on OrganizationUserScope {
                        organization
                        organizationCode
                        organizationName
                        organizationStatus
                        organizationCreatedAt
                        organizationDomain
                    }
                }
            }
        }
    }
`;
