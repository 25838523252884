const am_ET = require('./translation-locales/am_ET.json');
const ar_AR = require('./translation-locales/ar_AR.json');
const bg_BG = require('./translation-locales/bg_BG.json');
const bn_IN = require('./translation-locales/bn_IN.json');
const ca_ES = require('./translation-locales/ca_ES.json');
const cs_CZ = require('./translation-locales/cs_CZ.json');
const cy_GB = require('./translation-locales/cy_GB.json');
const da_DK = require('./translation-locales/da_DK.json');
const de_AT = require('./translation-locales/de_AT.json');
const de_DE = require('./translation-locales/de_DE.json');
const el_GR = require('./translation-locales/el_GR.json');
const en_GB = require('./translation-locales/en_GB.json');
const en_US = require('./translation-locales/en_US.json');
const es_ES = require('./translation-locales/es_ES.json');
const es_MX = require('./translation-locales/es_MX.json');
const et_EE = require('./translation-locales/et_EE.json');
const fa_AF = require('./translation-locales/fa_AF.json');
const fi_FI = require('./translation-locales/fi_FI.json');
const fr_BE = require('./translation-locales/fr_BE.json');
const fr_CA = require('./translation-locales/fr_CA.json');
const fr_CH = require('./translation-locales/fr_CH.json');
const fr_FR = require('./translation-locales/fr_FR.json');
const fr_LU = require('./translation-locales/fr_LU.json');
const fr_MC = require('./translation-locales/fr_MC.json');
const he_IL = require('./translation-locales/he_IL.json');
const hi_IN = require('./translation-locales/hi_IN.json');
const hr_HR = require('./translation-locales/hr_HR.json');
const hu_HU = require('./translation-locales/hu_HU.json');
const id_ID = require('./translation-locales/id_ID.json');
const it_IT = require('./translation-locales/it_IT.json');
const ja_JP = require('./translation-locales/ja_JP.json');
const ko_KR = require('./translation-locales/ko_KR.json');
const lt_LT = require('./translation-locales/lt_LT.json');
const lv_LV = require('./translation-locales/lv_LV.json');
const nl_BE = require('./translation-locales/nl_BE.json');
const nl_NL = require('./translation-locales/nl_NL.json');
const pl_PL = require('./translation-locales/pl_PL.json');
const pt_BR = require('./translation-locales/pt_BR.json');
const pt_PT = require('./translation-locales/pt_PT.json');
const ro_RO = require('./translation-locales/ro_RO.json');
const ru_RU = require('./translation-locales/ru_RU.json');
const sk_SK = require('./translation-locales/sk_SK.json');
const sl_SI = require('./translation-locales/sl_SI.json');
const sq_AL = require('./translation-locales/sq_AL.json');
const sv_SE = require('./translation-locales/sv_SE.json');
const sw_KE = require('./translation-locales/sw_KE.json');
const ta_IN = require('./translation-locales/ta_IN.json');
const th_TH = require('./translation-locales/th_TH.json');
const tr_TR = require('./translation-locales/tr_TR.json');
const uk_UA = require('./translation-locales/uk_UA.json');
const vi_VN = require('./translation-locales/vi_VN.json');
const zh_TW = require('./translation-locales/zh_TW.json');

const translations = {
    'am-ET': am_ET,
    'ar-AR': ar_AR,
    'bg-BG': bg_BG,
    'bn-IN': bn_IN,
    'ca-ES': ca_ES,
    'cs-CZ': cs_CZ,
    'cy-GB': cy_GB,
    'da-DK': da_DK,
    'de-AT': de_AT,
    'de-DE': de_DE,
    'el-GR': el_GR,
    'en-GB': en_GB,
    'en-US': en_US,
    'es-ES': es_ES,
    'es-MX': es_MX,
    'et-EE': et_EE,
    'fa-AF': fa_AF,
    'fi-FI': fi_FI,
    'fr-BE': fr_BE,
    'fr-CA': fr_CA,
    'fr-CH': fr_CH,
    'fr-FR': fr_FR,
    'fr-LU': fr_LU,
    'fr-MC': fr_MC,
    'he-IL': he_IL,
    'hi-IN': hi_IN,
    'hr-HR': hr_HR,
    'hu-HU': hu_HU,
    'id-ID': id_ID,
    'it-IT': it_IT,
    'ja-JP': ja_JP,
    'ko-KR': ko_KR,
    'lt-LT': lt_LT,
    'lv-LV': lv_LV,
    'nl-BE': nl_BE,
    'nl-NL': nl_NL,
    'pl-PL': pl_PL,
    'pt-BR': pt_BR,
    'pt-PT': pt_PT,
    'ro-RO': ro_RO,
    'ru-RU': ru_RU,
    'sk-SK': sk_SK,
    'sl-SI': sl_SI,
    'sq-AL': sq_AL,
    'sv-SE': sv_SE,
    'sw-KE': sw_KE,
    'ta-IN': ta_IN,
    'th-TH': th_TH,
    'tr-TR': tr_TR,
    'uk-UA': uk_UA,
    'vi-VN': vi_VN,
    'zh-TW': zh_TW,
};

export default translations;
