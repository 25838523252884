import {red} from '@material-ui/core/colors';
import buildUploader from "@genstackio/react-admin-core/lib/utils/buildUploader";
import logoPng from '@gotombola/logos/images/logo.png';
import logoFullPng from '@gotombola/logos/images/logo-full.png';
import sign from 'jwt-encode';

export {default as routes} from './routes';
export const app = {
    prefix: 'gotombola_app',
    endpoint: process.env.REACT_APP_API_ENDPOINT,
    defaultLocale: 'autodetect',
    fallbackLng: 'fr-FR',
    locales: ['fr-FR', 'de-DE', 'en-US', 'es-ES', 'it-IT', 'en-GB', 'nl-BE', 'pt-PT'],
    defaultPartner: 'gotombola',
};
export const apiOptions = {
    timeout: 25000,
    publicOperations: {
        getPublicOrganizationByPublicToken: true,
    },
};
export const logos = {
    logo: {url: logoPng, alt: 'GoTombola'},
    logoFull: {url: logoFullPng, alt: 'GoTombola'},
};
export const uploader = buildUploader(process.env.REACT_APP_UPLOADS_ENDPOINT, {anonymousify: () => sign({sub: 'anonymous'}, process.env.REACT_APP_UPLOADS_SECRET || '')});
export const theme = {
    tailwind: {},
    mui: {
        palette: {
            primary: {
                main: '#FBBD2A',
            },
            secondary: {
                main: '#2663E9',
            },
            error: {
                main: red.A400,
            },
            background: {
                default: '#FFFFFF',
            },
        },
    },
    theme: 'theme-color2',
};
export * as queries from './queries';
export {default as icons} from './icons';
export {default as translations} from './translations';
export {default as importers} from './importers';