// noinspection JSUnusedGlobalSymbols

export const LOGIN = (gql: any) => gql`
    mutation($data: CreateAuthTokenInput!) {
        createAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const REFRESH_LOGIN = (gql: any) => gql`
    mutation($data: RefreshAuthTokenInput!) {
        refreshAuthToken(data: $data) {
            token
            refreshToken
        }
    }
`;
export const CREATE_LOGIN_INVITATION_BY_GAME_CODE = (gql: any) => gql`
    mutation($data: CreateLoginInvitationByGameCodeInput!) {
        createLoginInvitationByGameCode(data: $data) {
            id
        }
    }
`;
export const COMPLETE_LOGIN_INVITATION_BY_SECRET_CODE = (gql: any) => gql`
    mutation($data: CompleteLoginInvitationBySecretCodeInput!) {
        completeLoginInvitationBySecretCode(data: $data) {
            token
        }
    }
`;
