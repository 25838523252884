import misc from './misc';
import game from './game';
import organization from './organization';
import user from './user';
import sponsor from './sponsor';
import donee from './donee';
import root from './root';
import mount from "../utils/mount";

export const index = [
    ...mount(misc, '/'),
    ...mount(game, '/my/game', '/orgs/:orgId/:orgPrivateToken/games/:gameId/:gamePrivateToken'),
    ...mount(organization, '/my/organization', '/orgs/:orgId/:orgPrivateToken'),
    ...mount(donee, '/my/donee'),
    ...mount(sponsor, '/my/sponsor'),
    ...mount(user, '/my/user'),
    ...mount(root, undefined, undefined, '/'),
];

export default index;