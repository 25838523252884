export const FIND_PRIVATE_ORGANIZATION_ORGANIZATION_TYPES = (gql: any) => gql`
    query($organization: OrganizationAuthInput) {
        findPrivateOrganizationOrganizationTypes(organization: $organization) {
            code
            name
            icon
            gameRank
            sponsorRank
            doneeRank
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION_PUBLIC_INFOS = (gql: any) => gql`
    query($organization: OrganizationAuthInput) {
        getPrivateOrganizationPublicInfos(organization: $organization) {
            id
            name
            status
            city
            zipCode
            name
            country
            logoImage {
                url
                available
            }
            websiteUrl
            facebookUrl
            domain
        }
    }
`;
export const GET_PRIVATE_ORGANIZATION = (gql: any) => gql`
    query($organization: OrganizationAuthInput) {
        getPrivateOrganization(organization: $organization) {
            id
            privateToken
            name
            status
            city
            zipCode
            name
            country
            presentation
            logoImage {
                url
                available
            }
            bannerImage {
                url
                available
            }
            websiteUrl
            facebookUrl
            domain
        }
    }
`;
export const FIND_PRIVATE_ORGANIZATION_AVAILABLE_PAYMENT_MODULES = (gql: any) => gql`
    query($organization: OrganizationAuthInput, $limit: Int = 100) {
        findPrivateOrganizationAvailablePaymentModules(organization: $organization, limit: $limit) {
            id
            code
            name
            rate
            flatTax
            currency
            url
            enabled
            public
            priority
            tags
            externalVideoUrl
            mainVideoUrl
            officialHelpUrl
            officialCreateAccountUrl
            countries
            kycProcess
            mode
        }
    }
`;
