const routes = {
    private: [
        {exact: false, secured: true, screen: 'private/organization/private_organization_layout', routes: [
            {path: '/', exact: true, secured: false, screen: 'private/organization/private_organization_home'},
        ]},
    ],
    publate: [
        {exact: false, secured: false, screen: 'publate/organization/publate_organization_layout', routes: [
            {path: '/', exact: true, secured: false, screen: 'publate/organization/publate_organization_home'},
            {path: '/mods/:moduleName', secured: false, screen: 'publate/organization/publate_organization_module'},
        ]},
    ],
};

export default routes;